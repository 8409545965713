body {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

#page-logo {
  text-align: center;
}

#app-title {
  text-align: center;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-weight: 600;
}

#widget-body {
  width: 1400px;
  margin: auto 5px auto 5px;
}

.flex-row-center {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.flex-row-left {
  display: flex;
  flex-direction: row;
  justify-content: left;
}

.flex-row-right {
  display: flex;
  flex-direction: row;
  justify-content: right;
}

.flex-column {
  display: flex;
  flex-direction: column;
  margin-bottom: 0px;
  margin-top: 0px !important;
}

.div-flt-row-2-col {
  width: 100%;
}

.div-flt-left {
  float: left;
}

.div-flt-right {
  float: right;
  text-align: right;
}

#app-column-1 {
  margin-left: auto;
  margin-right: 0;
}

#app-column-2 {
  margin-left: 0;
  margin-right: auto;
}

#size-column-1 {
  margin: 5px 30px auto 0;
}

#size-column-2 {
  margin: 5px auto auto 0;
}

#panels-header-flex-row {
  margin-top: 20px;
  min-width: 500px;
}

.add-component-button {
  background: #118f61;
  border-radius: 999px;
  box-shadow: #777 0 4px 3px -4px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-size: 15px;
  font-weight: 500;
  line-height: 16px;
  opacity: 1;
  outline: 0 solid transparent;
  padding: 2px 16px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: fit-content;
  word-break: break-word;
  border: 0;
  margin-bottom: 15px;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.add-component-button:hover,
.add-component-button:focus {
  background-color: #003f00;
  color: #fff;
  outline: 0;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2), 0 2px 6px 0 rgba(0, 0, 0, 0.15);
}

.panel-container {
  border-radius: 0 0 8px 8px;
  border: 1px solid black;
  margin: 0 5px 5px 5px;
  padding: 5px;
}

.standard-bg {
  background-color: #fff;
}

.required-container {
  background-color: #ffebe6;
  border-left: #e68250 5px solid;
  margin-bottom: 30px;
  margin-top: 20px;
  padding: 15px;
  border-radius: 0 8px 8px 0;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2), inset 0 1px 0 #fff;
}

.optional-container {
  background-color: #e5f5f8;
  border-left: #7fd1de 5px solid;
  margin: 5px;
  padding-top: 20px;
  padding-left: 15px;
  padding-bottom: 20px;
  border-radius: 0 8px 8px 0;
}

.container-header {
  border: 1px solid black;
  background-color: lightblue;
  margin: 0 5px 0 5px;
  padding: 5px;
  height: 18px;
}

.panel-container-header {
  border: 1px solid black;
  background-color: #118f61;
  color: white;
  margin: 0 5px 0 5px;
  padding: 5px;
  height: 18px;
}

.container-header-del-btn {
  border-radius: 3px;
  border-width: 1px;
  background: #f74545;
  border-radius: 999px;
  box-shadow: #777 0 4px 3px -4px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-size: 15px;
  font-weight: 500;
  line-height: 14px;
  opacity: 1;
  outline: 0 solid transparent;
  padding: 2px 12px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: fit-content;
  word-break: break-word;
  border: 0;
}

.container-header-del-btn:hover,
.container-header-del-btn:focus {
  color: #efefef;
  background-color: #970e0e;
  outline: 0;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2), 0 2px 6px 0 rgba(0, 0, 0, 0.15);
}

.container-header-min-btn {
  border-radius: 8px;
  border-width: 1px;
  background: white;
  color: black;
  margin: 0 0 0 5px;
}

.settings-btn {
  background: #118f61;
  border-radius: 999px;
  box-shadow: #777 0 4px 3px -4px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-size: 15px;
  font-weight: 500;
  line-height: 16px;
  opacity: 1;
  outline: 0 solid transparent;
  padding: 2px 16px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: fit-content;
  word-break: break-word;
  border: 0;
  margin-left: 5px;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.settings-btn:hover,
.settings-btn:focus {
  background-color: #003f00;
  color: #efefef;
  outline: 0;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2), 0 2px 6px 0 rgba(0, 0, 0, 0.15);
}

.settings-btn-cancel {
  border-radius: 3px;
  border-width: 1px;
  background: #f74545;
  border-radius: 999px;
  box-shadow: #777 0 4px 3px -4px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-size: 15px;
  font-weight: 500;
  line-height: 14px;
  opacity: 1;
  outline: 0 solid transparent;
  padding: 2px 10px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: fit-content;
  word-break: break-word;
  border: 0;
}

.settings-btn-cancel:hover,
.settings-btn-cancel:focus {
  background-color: #970e0e;
  color: #efefef;
  outline: 0;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2), 0 2px 6px 0 rgba(0, 0, 0, 0.15);
}

.size-input {
  width: 40px;
  margin-right: 5px;
}

.sub-ids-input {
  margin-right: 5px;
}

.label-lightgray {
  color: lightgray;
}

.label-black {
  color: black;
}

.component-container {
  background-color: #efefef;
  border-radius: 8px 8px 8px 8px;
  border: 1px solid lightgray;
  margin: 5px;
  padding: 15px;
}

#preview-container {
  text-align: center;
  padding: 15px;
  border: 1px dashed #999;
}

#markup-container {
  border: 1px solid #dedede;
  overflow: scroll;
  padding: 5px 5px 5px 5px;
  background-color: white;
  margin-bottom: 20px;
}

#markup-output {
  max-width: 1300px;
  max-height: 600px;
  margin-right: auto;
  margin-left: auto;
  padding: 15px;
  font-family: monospace;
}

.component-header {
  margin-bottom: 5px;
  padding-bottom: 5px;
  font-weight: 600;
  font-size: 17px;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.component-header-with-btn {
  padding-top: 0;
  margin-top: 1px;
}

#widget-id-input {
  width: 70px;
  margin: 0px 15px 2px 5px;
  border: 1px solid #ededed;
  border-radius: 3px;
  height: 17px;
}

.layout-label {
  margin: 10px 5px 0 5px;
}

.settings-item {
  margin: 0px 10px 20px 0px;
}

.settings-input-item {
  margin: 10px 5px 5px 5px;
  border: 1px solid #ededed;
  border-radius: 3px;
  padding: 3px;
}

.size-container {
  margin: 10px 0 10px 5px;
}

.sub-id-input-container {
  margin: 0px;
  padding: 0px;
}

.sub-id-values-list {
  margin: 5px 5px 10px 5px;
}

.sub-id-item {
  margin: 5px;
}

label.group-label {
  font-weight: 600 !important;
  font-size: 16px;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  margin-bottom: 10px;
}

.group-label {
  font-weight: 600 !important;
  font-size: 16px;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  margin-bottom: 10px;
}

#learn-more-btn-color-label {
  margin: 0px 0px 0px 5px;
}

.color-select-preview {
  width: 14px;
  height: 14px;
  border-radius: 9px;
  border: 1px black solid;
  margin: 2px 0 2px 5px;
}

.color-select-display {
  font-size: x-small;
  height: 100%;
  padding: 2px 2px 5px 2px;
  margin-top: 3px;
  max-height: 10px;
}

.row {
  display: flex;
}

.column-right {
  flex: 50%;
}

.column-left {
  flex: 50%;
}

.help-link {
  font-size: 14px;
  margin-left: 5px;
  color: blue;
  text-decoration: none;
}

.hide-show-btn {
  margin-top: 0px;
  padding: 0;
  border-width: 0;
  cursor: pointer;
}

.hide-show-img {
  width: 18px;
  height: 15px;
  margin: 0 0 0 0;
  padding: 0;
  top: 25px;
  left: 9px;
  position: relative;
}

.flex-column select {
  border: 1px solid #efefef;
  padding-left: 4px;
  padding-right: 4px;
  border-radius: 3px;
  margin-top: 0px;
  font-size: 14px;
  font-family: Arial, Helvetica, sans-serif;
}

.flex-row-right .flex-column {
  margin-right: 8px;
  font-size: 15px;
  font-weight: 500 !important;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.color-select-display {
  font-size: 14px;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  padding-left: 4px;
  padding-right: 4px;
}

.chrome-picker {
  width: 245px !important;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif !important;
}

input[type="checkbox"] {
  position: relative;
  cursor: pointer;
  margin-top: 0px;
  margin-bottom: 0px;
  bottom: -1px;
}
input[type="checkbox"]:before {
  content: "";
  display: block;
  position: absolute;
  width: 13px;
  height: 13px;
  top: 0;
  left: 0;
}
input[type="checkbox"]:checked:before {
  content: "";
  display: block;
  position: absolute;
  width: 13px;
  height: 13px;
  top: 0px;
  left: 0px;
  background-color: #003f00;
}
input[type="checkbox"]:checked:after {
  content: "";
  display: block;
  width: 4px;
  height: 7px;
  border: 1px solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: 1px;
  left: 4px;
}

.sub-id-item button {
  border-radius: 3px;
  border-width: 1px;
  background: #f74545;
  border-radius: 999px;
  box-shadow: #777 0 4px 3px -4px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-size: 15px;
  font-weight: 500;
  line-height: 14px;
  opacity: 1;
  outline: 0 solid transparent;
  padding: 2px 10px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: fit-content;
  word-break: break-word;
  border: 0;
}

.sub-id-item button:hover,
.sub-id-item button:focus {
  background-color: #970e0e;
  color: #efefef;
  outline: 0;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2), 0 2px 6px 0 rgba(0, 0, 0, 0.15);
}

/* React Tabs */
.react-tabs {
  -webkit-tap-highlight-color: transparent;
  border-radius: 9px;
  background: #efefef;
  margin-bottom: 20px;
  padding: 5px;
}

.react-tabs__tab-list {
  border-bottom: 1px solid #aaa;
  margin: 0 0 0 0;
  padding: 0;
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
}

.react-tabs__tab {
  display: inline-block;
  border: 1px solid transparent;
  border-bottom: none;
  bottom: -1px;
  position: relative;
  list-style: none;
  padding: 6px 12px;
  cursor: pointer;
  font-weight: normal;
}

.react-tabs__tab--selected {
  background: #333;
  color: #fff;
  border-radius: 5px 5px 0 0;
  border: 1px solid #333;
}

.react-tabs__tab--disabled {
  color: GrayText;
  cursor: default;
}

.react-tabs__tab:focus {
  outline: none;
}

.react-tabs__tab-panel {
  display: none;
}

.react-tabs__tab-panel--selected {
  display: block;
  border: 1px solid #aaa;
  border-radius: 0 0 5px 5px;
  border-top: 0;
  padding: 10px;
  margin: 0;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2), inset 0 1px 0 #fff;
}
/* React Tabs END */

/*** Tooltip Stuff ***/
/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tool-tip-text {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tool-tip-text {
  visibility: visible;
}

/*** Tooltip Stuff End ***/
